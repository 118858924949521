.contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    width: 100%;
    padding-top: 200px;
    animation: fadeIn 0.5s ease-in-out;
    /* height: 100vh; */
    /* overflow: hidden; */
}

.contact p {
    margin-left: 20%;
}

.contact p, .contact a {
    /* width: 60%; */
    font-family: "Min-P-R";
    font-size: 16px;
    margin-bottom: 12px;
}

@media only screen and (max-width: 950px) {

    .contact {
        padding-top: 150px;
    }

}