.about {
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    width: 100%;
    /* height: 100vh; */
    padding-top: 200px;
    padding-bottom: 200px;
    animation: fadeIn 0.5s ease-in-out;
    /* overflow: hidden; */
}

.aboutText {
    width: 50%;
    margin-left: 20%;
    /* padding-top: 15%; */
}

.about p {
    font-family: "Min-P-R";
    font-size: 16px;
    margin-bottom: 16px;
}

.cvButton {
    align-self: flex-start;
    margin-top: 50px;
    margin-left: 20%;
    cursor: pointer;
    text-decoration: underline;
}

.cv {
    width: 50%;
    margin-left: 20%;
    /* padding-bottom: 50px; */
}

@media only screen and (max-width: 950px) {

    .about {
        padding-top: 150px;
        padding-bottom: 100px;
    }

    .aboutText {
        width: calc(100% - 60px);
        margin: 0px 30px;
    }

    .cvButton {
        margin-left: 30px;
    }

    .cv {
        width: calc(100% - 60px);
        margin: 0px 30px;
    }

}