@keyframes fadeIn {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

.work {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    /* width: 100%;
    height: 100vh;
    overflow: hidden; */
    padding: 11% 0% 0% 0%;
    margin-bottom: 50px;
    animation: fadeIn 0.5s ease-in-out;
}

#workImgContainer {
    /* display: flex; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 45%;
    /* height: 63vw; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    /* background-color: aquamarine; */
    /* border: 1px solid black; */
}

.workImg {
    position: relative;
    /* display: flex; */
    justify-content: center;
    align-items: center;
}

.workImg>img {
    object-fit: scale-down;
    width: 100%;
    height: auto;
}

.workImg>p {
    position: absolute;
    /* width: 100%;
    height: 100%; */
}

.imgChangeButtons {
    display: flex;
    position: relative;
    z-index: 10;
    width: 100%;
    height: 100%;
}

.imgChangeButtons>div {
    display: flex;
    align-items: center;
    position: fixed;
    width: 15%;
    height: 30%;
    top: 35%;
    font-size: 20px;
    cursor: pointer;
}

.workArrows {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 2;
    /* width: 10%;
    height: 10%; */
    bottom: 0;
    padding: 20px;
    font-size: 18px;
    cursor: pointer;
    font-family: "Min-P-B";
    /* background-color: lime; */
}

.workArrows:last-of-type {
    right: 0;
    /* justify-content: flex-end; */
}

.imgArrows {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    z-index: 200;
    width: 50%;
    height: 100%;
    top: 0;
    padding: 5px;
    font-size: 20px;
    /* background-color: lime; */
    /* background: linear-gradient(90deg, rgba(255, 255, 255, 0.3), transparent); */
    cursor: url("cursor_left.png"), auto;
}

.imgArrows>span {
    cursor: pointer;
}

.imgArrows:last-of-type {
    right: 0;
    justify-content: flex-end;
    cursor: url("cursor_right.png"), auto;
    /* background: linear-gradient(-90deg, rgba(255, 255, 255, 0.3), transparent); */
}

.indicator {
    display: inline-block;
    width: 7px;
    height: 7px;
    margin: 5px;
    border: 1px solid black;
    border-radius: 7px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.imgChangeButtons>div:first-of-type {
    /* cursor: w-resize; */
    justify-content: flex-start;
    left: 6%;
    /* background-color: blue; */
}

.imgChangeButtons>div:last-of-type {
    /* cursor: e-resize; */
    justify-content: flex-end;
    right: 6%;
    /* background-color: lime; */
}

#workImgInfo {
    /* position: absolute; */
    margin-top: 20px;
    margin-bottom: 40px;
    font-family: "Min-P-R";
    font-size: 16px;
}

#workImgContainer img {
    max-width: 100%;
    max-height: 90vh;
    transition: opacity 0.3s ease-in-out;
}

#workInfoContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 40%;
    /* height: 60vh; */
    /* border: 1px solid black; */
    /* background-color: lightcoral; */
}

#workTitle>p {
    font-family: "Min-P-R";
    font-size: 20px;
}

#workTitle>p>span {
    font-family: "Min-P-B-I";
}

#workTitle {
    align-self: flex-start;
    margin-left: 50px;
    text-align: left;
    /* background-color: lime; */
}

#workDescription {
    margin: 20px 50px;
    font-family: "Min-P-R";
    font-size: 16px;
}

#workDescription>p {
    text-align: left;
    margin-bottom: 16px;
}

.arrows {
    display: flex;
    align-items: flex-end;
    position: fixed;
    z-index: 10;
    width: 10%;
    height: 25%;
    bottom: 0;
    font-family: "Min-P-B";
    font-size: 16px;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
    cursor: pointer;
    /* transition: all 0.2s; */
    /* background-color: lime; */
}

.arrows>div {
    margin-bottom: 40px;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
    pointer-events: none;
}

.leftGrad {
    position: absolute;
    background: linear-gradient(to left, transparent, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.5));
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: opacity 0.5s;
}

.rightGrad {
    position: absolute;
    background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.8));
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: opacity 0.3s;
}

.left {
    justify-content: flex-start;
    left: 0;
    padding-left: 5%;
}

.right {
    justify-content: flex-end;
    right: 0;
    padding-right: 5%;
}

.left:hover,
.right:hover {
    opacity: 1;
}

.carousel-slider {
    overflow: visible !important;
}

.carousel-status {
    top: 100% !important;
    left: 0;
    font-family: "Min-P-R" !important;
    font-size: 12px !important;
    color: black !important;
    text-shadow: none !important;
    padding-top: 20px !important;
}

@media only screen and (max-height: 700px) {

    .work {
        height: 700px;
    }

}

@media only screen and (max-width: 950px) {

    .work {
        display: block;
        /* flex-direction: column; */
        overflow: scroll;
    }

    #workImgContainer {
        width: 100%;
        margin-top: 100px;
        margin-bottom: 50px;
    }

    #workImgInfo {
        bottom: -5px;
    }

    #workInfoContainer {
        width: 80%;
        height: max-content;
        padding: 0px 10% 100px 10%;
    }


    #workDescription {
        overflow: auto;
    }

    .arrows {
        position: fixed;
        z-index: 10;
        bottom: 0;
        width: 60px;
        cursor: pointer;
    }

    .right {
        right: 0;
    }

    .left {
        left: 0;
    }

    .arrowBlocker {
        display: none;
    }

    .imgChangeButtons>div:first-of-type {
        /* cursor: w-resize; */
        justify-content: flex-start;
        left: 8%;
        /* background-color: blue; */
    }

    .imgChangeButtons>div:last-of-type {
        /* cursor: e-resize; */
        justify-content: flex-end;
        left: 77%;
        /* background-color: lime; */
    }

}

@media only screen and (max-width: 450px) {

    .work {
        padding: 0% 0% 0% 0%;
    }

    #workImgContainer {
        margin-top: 150px;
    }

    #workImgContainer img {
        max-width: 100%;
        max-height: 100%;
    }

    #workInfoContainer {
        width: 100%;
        padding: 0px 0% 100px 0%;
        /* background-color: lime; */
    }

    #workTitle {
        margin-left: 30px;
    }

    #workDescription {
        margin: 20px 30px;
    }

    .arrowBlocker {
        display: none;
    }

}