.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    font-family: "Min-P-R";;
    font-size: 16px;
    
}

@keyframes fadeIn {
    0% {opacity: 0}
    100% {opacity: 1}
}

.works {
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 150px 10% 100px 10%;
    animation: fadeIn 0.5s ease-in-out;
    /* background-color: red; */
}

.workContainer {
    /* display: flex; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* width: calc(80 / 3%); */
    width: 26.7vw;
    height: 35vw;
    /* margin-left: 10%; */
    margin-bottom: 150px;
    /* margin-bottom: 90px; */
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    /* border: 1px solid black; */
    /* background-color: blue; */
}

.workImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 100%;
    margin-left: 10%;
    margin-bottom: 10px;
    /* border: 1px solid blue; */
    overflow: hidden;
}

.workImageContainer img {
    /* max-height: calc(45vw * 0.6); */
    max-width: 100%;
    /* box-shadow: 0px 0px 20px grey; */
}

.workInfoContainer {
    width: 80%;
    margin: 0% 10%;
    /* background-color: lime; */
}

.workInfoContainer p {
    /* width: 60%; */
    font-family: "Min-P-R";
    font-size: 12px;
    /* line-height: 15px; */
    /* text-align: center; */
}

.workInfoContainer p span {
    font-family: "Min-P-B-I";
}

@media only screen and (max-width: 1000px) {

    .works {
        grid-template-columns: auto auto;
    }

    .workContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 40vw;
        height: 60vw;
    }

}

@media only screen and (max-width: 800px) {

    .works {
        display: block;
        padding: 150px 10% 10% 10%;
    }

    .workContainer {
        display: block;
        width: auto;
        height: auto;
        margin-bottom: 80px;
    }

    .workImageContainer {
        width: 100%;
        margin-left: 0;
    }

    .workImageContainer img {
        width: 100%;
    }

}

@media only screen and (max-width: 450px) {

    .works {
        display: block;
        padding: 150px 0% 10% 0%;
    }

    .workInfoContainer {
        margin: 5% 5%;
    }

    .workInfoContainer p {
        font-family: "Min-P-R";
        font-size: 20px;
        /* line-height: 15px; */
    }

}