@font-face {
    font-family: "Min-P-B-I";
    src: url("./fonts/Minion Pro Bold Italic.ttf")
}

@font-face {
    font-family: "Min-P-B";
    src: url("./fonts/Minion Pro Bold.ttf")
}
/*
@font-face {
    font-family: "Min-P-B";
    src: url("./fonts/MinionPro-Semibold.otf")
} */

@font-face {
    font-family: "Min-P-R";
    src: url("./fonts/Minion Pro Regular.ttf")
}

@font-face {
    font-family: "Arial-L";
    src: url("./fonts/Arial Light.ttf")
}

* {
    margin: 0;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.app {
    width: 100vw;
    height: 100vh;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.app::-webkit-scrollbar {
    display: none;
}

body {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

body::-webkit-scrollbar {
    display: none;
}

a {
    color: black;
    text-decoration: none;
}