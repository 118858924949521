.header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: fixed;
    z-index: 100;
    width: 100%;
    padding-top: 40px;
    /* background: linear-gradient(white, white, transparent); */
    /* background-color: lime; */
}

h1 {
    font-size: 29px;
    margin-left: 5%;
}

h1 a {
    font-family: 'Min-P-B';
    color: black;
    text-decoration: none;
}

nav {
    display: flex;
    margin-right: 5%;
    /* background-color: blue; */
}

nav p {
    /* font-family: 'Minion'; */
    margin-left: 40px;
    margin-bottom: 5px;
}

nav a {
    font-family: "Min-P-R";
    color: black;
    text-decoration: none;
}

#mobileNavButton {
    /* display: none; */
    position: relative;
    margin-right: 5%;
    margin-bottom: 3px;
    font-size: 29px;
    cursor: pointer;
    /* background-color: lime; */
}

#hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 20px;
    height: 15px;
    margin-bottom: 7px;
    /* background-color: lime; */
}

#hamburger > div {
    width: 100%;
    height: 3px;
    border-radius: 1px;
    background-color: black;
}

#cross {
    width: 20px;
    height: 20px;
    margin-bottom: 10px;
    /* background-color: lime; */
}

#cross > div {
    position: absolute;
    width: 100%;
    height: 3px;
    top: 14px;
    border-radius: 1px;
    background-color: black;
}

#cross > div:first-of-type {
    transform: rotate(45deg);
}

#cross > div:last-of-type {
    transform: rotate(-45deg);
}

@keyframes fadeIn {
    0% {opacity: 0}
    100% {opacity: 1}
}

#mobileNav {
    display: flex;
    position: absolute;
    bottom: 1px;
    right: 50px;
    animation: fadeIn 0.5s ease-in-out
}

#mobileNav p {
    font-family: 'Min-P-R';
    font-size: 18px;
    /* text-align: right; */
    margin-left: 20px;
}

@media only screen and (max-width: 600px) {

    nav {
        display: none;
    }

    #mobileNavButton {
        display: block;
    }

    #mobileNav {
        position: absolute;
        top: 60px;
        right: 0;
        animation: fadeIn 0.5s ease-in-out
    }

    #mobileNav p {
        font-family: 'Min-P-R';
        font-size: 25px;
        text-align: right;
    }

}